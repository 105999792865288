import React, { Component, useState } from 'react';
import { IonPopover, IonButton, IonSpinner, IonToast } from '@ionic/react';

import axios from 'axios';
import './SeatMap.css';

export default class SeatMap extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      token: JSON.parse(window.localStorage.getItem('token') as string),
      map: {},
      settings: {
        loading: false,
        toast: false,
        toastMessage: null,
        highlight: false,
        popover: null,
      }
    };
  }

  getMap() {
    this.setState({
      settings: {
        loading: true,
      }
    })
    axios.get(
      'https://ukom-boarding.kssg.ch/api/v1/map/' + (this.props.place === 'Office' ? 1 : 2) + '?day=' + this.props.day + '&thisWeek=' + this.props.today + '&halfDay=' + this.props.halfDay,
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      // console.log(res.data);
      const map = res.data;
      this.setState({ map });
      this.setState({
        settings: {
          loading: false,
        }
      })
    })
  }

  refreshUser() {
    // console.log('refresh user');
    this.props.refreshMap();
  }

  changeHighlight(seat) {
    if (seat && seat.id) {
      if (!seat.enabled) {
        return;
      }

      // console.log('change highlight', seat)
      //this.state.settings.highlight = seat.id
      //this.setState(this.state);
      if (this.props.day !== 'week') {
        this.props.refreshMap();
      }

      this.getMap();
      // console.log('change highlight', seat, seat.id, this.state.settings);
    }
  }

  async saveSeatForUser(seat) {
    this.setState({
      settings: {
        loading: true,
      }
    })

    console.log('save seat for user here', { seat: seat, today: this.props.today, booking: this.props.booking, day: this.props.day }, this.props)

    let booking = this.props.booking;

    if (this.props.day == 'week') {
      booking = { place: this.props.place }
    }

    let vm = this;
    await axios.post(
      'https://ukom-boarding.kssg.ch/api/v1/changeseat',
      { seat: seat, today: this.props.today, booking: booking, day: this.props.day, halfDay: this.props.halfDay },
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.token.access_token,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      this.setState({
        settings: {
          loading: false,
        }
      })

      // console.log('successfully .... res. ', res.data);

      vm.changeHighlight(seat);
      vm.refreshUser();
      //console.log(vm.props)
      // if (vm.props.today) {
      //   vm.props.history.push('/dashboard?tab=today')
      // } else {
      //   vm.props.history.push('/dashboard?tab=tomorrow')
      // }

      //window.location.reload()
    })
      .catch(function (error) {
        console.log(error)
        if (error.response?.status === 422) {
          vm.state.settings.toastMessage = error.response.data.message;
          vm.state.settings.toast = true;
          vm.setState(vm.state);
        }

        console.log(error?.toJSON());
      })
  }

  selectSeat(seat) {
    // console.log('seat comp.', seat)

    if (this.props.viewOnly) {
      return;
    }

    if (!this.props.viewOnly && seat.enabled) {
      this.saveSeatForUser(seat);
    }

    seat.show = !seat.show;
    this.setState(this.state);
    if (this.props.viewOnly) {
      return;
    }

    this.props.selectSeat(seat)
  }

  componentDidMount() {
    // console.log('seatmap mounted', this.props, this.props.today, !this.props.today)

    this.getMap()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.day !== this.props.day) {
      this.getMap()
    }

    if (prevProps.place !== this.props.place) {
      this.getMap()
    }
  }

  showPopover(value) {
    this.state.settings.popover = value;
    this.setState(this.state)
  }

  showSeat(seat) {

    if (!seat.enabled) {
      seat.show = true;
      this.setState(this.state);
    }

    if (seat.day && seat.day[0] && seat.day[0]['user'] && seat.day[0]['user']['name']) {
      seat.show = true;
      this.setState(this.state);
    }
  }

  hideSeat(seat) {

    if (!seat.enabled) {
      seat.show = false;
      this.setState(this.state);
    }

    if (seat.day && seat.day[0] && seat.day[0]['user'] && seat.day[0]['user']['name']) {
      seat.show = false;
      this.setState(this.state);
    }
  }

  getHighlightedSeat(seat) {

    if (seat.day.length > 0) {
      // return seat.day[0].current_user;
    }

    // console.log(this.props.highlight)
    // console.log(this.state.props.highlight)


    if (this.state.settings.highlight) {
      return seat.id === this.state.settings.highlight
    }

    return seat.id === this.props.highlight
  }

  closeToast() {
    this.state.settings.toast = false;
    this.setState(this.state);
  }

  showToast() {
    this.state.settings.toast = true;
    this.setState(this.state);
  }

  render() {
    return <div>

      <IonToast
        color="danger"
        isOpen={this.state.settings.toast}
        onDidDismiss={() => this.closeToast()}
        message={this.state.settings.toastMessage}
        duration={2000}
        position="top"
      />

      {this.state.map.plan &&
        <div style={{ position: 'relative' }}>
          <img src={'https://ukom-boarding.kssg.ch/storage/' + this.state.map.plan} className="w-full" />

          {!this.props.today &&
            <div>
              {this.state.map.seats.map((seat, index) => {
                return <div
                  key={index}
                  className={[(!seat.enabled || seat.day?.length > 0) ? 'seat seat-disabled' : "seat", (this.props.seat && seat.id === this.props.seat.id) ? 'seat-selected' : '', (this.getHighlightedSeat(seat)) ? 'seat-highlighted' : ''].join(' ')}
                  style={{ top: seat.settings.height + '%', left: seat.settings.width + '%' }}
                  onClick={() => this.selectSeat(seat)}
                  onMouseEnter={() => this.showSeat(seat)}
                  onMouseLeave={() => this.hideSeat(seat)}
                >
                  {seat.name}
                  {(seat.day?.length > 0 && seat.show && seat.day[0]['user']) &&
                    <div className="seat-popover">
                      {seat.day[0]['user']['name']}
                    </div>
                  }
                  {(!seat.enabled && seat.show && seat.description) &&
                    <div className="seat-popover">
                      {seat.description}
                    </div>
                  }
                </div>
              })}
            </div>
          }


          <div>
            {this.state.map.seats.map((seat, index) => {
              return <div
                key={index}
                className={[(!seat.enabled || seat.day?.length > 0) ? 'seat seat-disabled' : "seat", (seat.day?.length > 0 && seat.day[0].place == 'Halbtage') ? 'seat-halfday' : '', (seat.day?.length > 0 && seat.day[0].place == 'Halbtage' && seat.day[0].pivot.settings == 'afternoon') ? 'seat-halfday-afternoon' : '', (seat.day?.length > 0 && seat.day[0].place == 'Halbtage' && seat.day[0].pivot.settings == 'morning') ? 'seat-halfday-morning' : '', (this.props.seat && seat.id === this.props.seat.id) ? 'seat-selected' : '', (this.getHighlightedSeat(seat)) ? 'seat-highlighted' : 'not'].join(' ')}
                style={{ top: seat.settings.height + '%', left: seat.settings.width + '%' }}
                onClick={() => this.selectSeat(seat)}
                onMouseEnter={() => this.showSeat(seat)}
                onMouseLeave={() => this.hideSeat(seat)}
              >
                {seat.name}
                {(seat.day?.length > 0 && seat.show && seat.day[0]['user'] && seat.day[0]['user']['name']) &&
                  <div className="seat-popover">
                    {seat.day[0]['user']['name']}
                  </div>
                }
                {(!seat.enabled && seat.show && seat.description) &&
                  <div className="seat-popover">
                    {seat.description}
                  </div>
                }
              </div>
            })}
          </div>

        </div>
      }

      {this.state.settings.loading &&
        <div className="loader">
          <IonSpinner name="dots" />
        </div>
      }
    </div>;
  }
}
